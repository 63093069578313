'use client';

import { Locale } from '@/i18n/routing';
import { useLocale, useTranslations } from 'next-intl';
import Link from 'next/link';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';

const SwitchLocaleButton = () => {
	const router = useRouter();
	const pathname = usePathname();
	const searchParams = useSearchParams();

	const locale = useLocale() as Locale;
	const targetLocale = locale === 'en' ? 'nl' : 'en';
	const translations = useTranslations('SwitchLocaleButton');

	return (
		<Link
			href={`/${targetLocale}${pathname.replace(
				`/${locale}`,
				'',
			)}?${searchParams}`}
			className="absolute right-4 top-4 z-20 rounded-full bg-white/90 px-4 py-1 text-slate-900 transition-all hover:opacity-80"
			lang={targetLocale}
			onClick={() => {
				const currentFullUrl = window.location.href;
				const [currentBaseUrl, currentPathAndEverythingAfter] =
					currentFullUrl.split(window.location.origin);

				const newPath = currentPathAndEverythingAfter.replace(
					new RegExp(`^/${locale}/?`),
					`/${targetLocale}/`,
				);

				router.push(
					`${window.location.origin}${
						currentBaseUrl === '/' ? '' : currentBaseUrl
					}${newPath}`,
				);
			}}
		>
			{translations('switch_language')}
		</Link>
	);
};

export default SwitchLocaleButton;
